import React, { useEffect, useState } from 'react'
import "./live.scss"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { toast } from "react-toastify";
import Countdown from "react-countdown";
import CheckExpire from "../../../hooks/dataFetchers/checkExpiry";
import Environment from "../../../utils/Environment";
import { useWeb3React } from '@web3-react/core';
import axios from "axios";
import { Link } from 'react-router-dom';
import Market from '../../Marketplace/Market';
const LiveAuction = () => {
    const [expTime, setExpTime]=useState([])
    const [allNFTS, setAllNFTS] = useState([]);
    const [loader, setLoader] = useState(false)
    // console.log('asfkd;a;', allNFTS)
    const { account } = useWeb3React()
    const { CheckExpiry } = CheckExpire();
    // const expTimeFun = (arr) => {
    //     console.log('adlfjalsdfjlasfd',arr)
    //     setLoader(true);
    //     var data = JSON.stringify({
    //         query: `query MyQuery {
    //             auctionCreateds(
    //               orderBy: mintAmount
    //               orderDirection: asc
    //               where: {tokenId_in: ${JSON.stringify(arr)}}
    //             ) {
    //               expiryTime
    //               label
    //               tokenId
    //             }
    //           }`,
    //         variables: {},
    //     });

    //     var config = {
    //         method: "post",
    //         url: Environment.marketplaceGraph,
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         data: data,
    //     };

    //     axios(config)
    //         .then(async (response) => {
    //             setLoader(true);
    //             var dumAuction = [];
    //             let minted = response?.data?.data?.auctionCreateds;
                
                
    //             for (let nft of minted) {
    //                 let dat = new Date(0);
    //                 dat.setUTCSeconds(parseFloat(nft?.expiryTime));
    //                 dumAuction.push(dat)
    //             }
    //             setExpTime(dumAuction);
    //             setLoader(false);
    //         })
    //         .catch((err) => {
    //             console.log('sdf', err)
    //             setLoader(false);
    //         });
    //     setLoader(true);
    // };
    const OnAuction = () => {
        setLoader(true);
        var data = JSON.stringify({
            query: `query MyQuery {
                bidCreateds(first: 12, orderBy: bidTime, orderDirection: desc) {
                  amount
                  label
                  tokenId
                  tld
                }
              }`,
            variables: {},
        });

        var config = {
            method: "post",
            url: Environment.marketplaceGraph,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(async (response) => {
                setLoader(true);
                var dumAuction = [];
                let minted = response?.data?.data?.bidCreateds;
                const uniquePairs = new Set();
               const uniqueData = [];

               minted.forEach(obj => {
                 const pair = obj.label + obj.tld;
  
                 if (!uniquePairs.has(pair) && uniqueData.length < 8) {
                  uniquePairs.add(pair);
                  uniqueData.push(obj);
                }
});
for (let nft of uniqueData) {
  let resauctioned
  try {
    resauctioned = await CheckExpiry(nft?.tokenId);
  } catch (error) {
  }

  let dat = new Date(0);
  dat.setUTCSeconds(parseFloat(resauctioned?.expiryTime));
  let dateVal = null;
  if (dat) {
    const date0 = new Date();
    if (dat > date0) {
      dumAuction.push(nft);
    }
  }
}


console.log(dumAuction);
                setAllNFTS(dumAuction);
                // let dumArr=[]
                // for (let nft of minted) {
                //     dumArr.push(nft?.tokenId?.toString())
                // }
                // console.log('dummarr',dumArr)
                setLoader(false);
            })
            .catch((err) => {
                // console.log('sdf', err)
                setLoader(false);
            });
        setLoader(true);
    };
    function formatAmount(amount) {
      return (parseInt(amount) / 1000000000000000000)?.toFixed(2);
    }
    
    function displayAmount(amount) {
      const parsedAmount = formatAmount(amount);
      if (parsedAmount && parsedAmount >= 10000000) {
        return `${parsedAmount?.toString()?.slice(0, 9)}...`;
      }
      return parsedAmount;
    }
    useEffect(() => {
        OnAuction()
    }, [account])
    let metaData= [0,1]
    const display = allNFTS
    ?.map((elem, id) => {
      return (
        <div className="custom-cards">
          <Link to={{ pathname: `/place/` + elem.tokenId }}>
            <div class="card">
            <svg xmlns="http://www.w3.org/2000/svg" width="195" height="277" viewBox="0 0 195 277" fill="none" className='cardimg'>
  <path d="M140.005 46.7178C190.547 46.7178 231.748 86.9204 233.65 137.251L277.988 164.613C279.313 156.837 280 148.851 280 140.694C280 62.991 217.327 0 139.995 0C62.6626 0 0 63.001 0 140.704C0 157.528 2.93793 173.671 8.33576 188.613C13.6738 203.435 21.422 217.086 31.1023 229.116L70.9088 204.546L116.81 176.213V284H161.985V175.342L209.181 204.476L248.977 229.045C258.648 217.016 266.386 203.355 271.714 188.523L231.041 163.412L184.293 134.559L140.105 107.287L140.005 107.347L95.9161 134.559L48.9988 163.512C47.2161 156.267 46.27 148.71 46.27 140.914C46.27 88.892 88.2376 46.7278 139.995 46.7278L140.005 46.7178ZM140.005 161.771L140.095 161.821L140.005 161.881V161.761V161.771Z" fill="white" fill-opacity="0.03"/>
</svg>
            <div class="card-style-2">
                  <h5 class="card-heading-1">Highest Bid</h5>
                  <p class="card-heading-2 text-lowercase">
                    <img
                      class="img-fluid mr-1 mytomiicon"
                      src="\assets\eth-icon.svg"
                      alt="img"
                    />
                    {/* {// console.log('hbid===>', metaData, elem?.HighestBid, parseInt(elem?.HighestBid).toFixed(4) /
                      1000000000000000000)} */}
                 <span className='text-truncate'>{displayAmount(elem?.amount) || "0"}</span>

                  </p>
                </div>
              <div className="card-img-top-div ">
                <span className=" w-100 text-left set-text-font">{elem?.label?.substring(0, 13)}{elem?.label?.length > 13 && '(...)'}.{elem?.tld}</span>
              </div>
              <div class="card-body-1">
                <div class="card-style-2">

                  {/* {elem?.Auction === true && ( */}
                    <>
                      {/* <h5 class="card-heading-4">Auction Ends In</h5> */}
                      <p class="card-heading-6 justify-content-start">
                      <h5 className="endedspan">Ended</h5>
                        {/* {true && (
                          <Countdown
                            date={'Wed May 15 2024 16:44:35 GMT+0500 (Pakistan Standard Time)'}
                            renderer={({ days, hours, minutes, seconds }) => {
                              return (
                                <>
                                  <h5 class="card-heading-6">
                                    <div className="timermain">
                                    <span className="timerinner">{days < 10 && 0}{days}</span>
                                    <p className="timerpara">DAYS</p>
                                    </div> 
                                    <div className="timermain">
                                    <span className="timerinner">{hours < 10 && 0}{hours}</span>
                                    <p className="timerpara">HOURS</p>
                                    </div> 
                                    <div className="timermain">
                                    <span className="timerinner">{minutes < 10 && 0}{minutes}</span>
                                    <p className="timerpara">MINUTES</p>
                                    </div> 
                                    <div className="timermain">
                                    <span className="timerinner">{seconds < 10 && 0}{seconds}</span>
                                    <p className="timerpara">SECONDS</p>
                                   </div>
                                  </h5>
                                </>
                              );
                            }}
                          ></Countdown>
                        )} */}
                      </p>
                    </>
                    {/* // )} */}
                </div>
          
              </div>
            </div>
          </Link>
        </div>
      );
    });
    return (
        <>
        <div className="liveAuc">
          <section className="wrappper-market liveauction market">
                <div className="custom-containerl">
                    <div className="top-heading d-flex justify-content-between flex-wrap ml-3">
                        <h5>Hottest auctions 🔥 </h5>
                       <Link to='/marketplace'> <button className='button-hedaerrrrr'>View All <img src="\assets\newlanding-assets\arrow-right-new.svg" alt="img" className='img-fluid ml-2' /></button></Link>
                    </div>
                    {display.length > 0 ? 
                    <div class="cardsss">
                          {(display ? display : "")}

                        </div>
                        :loader || <h6 className='auctionendtext text-center'>There are currently no auctions available.<br></br> Keep an eye on the auction page to be the first to discover new listings</h6>
                    }
                        {loader &&
            <div className="d-flex mt-5 flex-wrap align-items-center justify-content-center">
                  <img
                    width={35}
                    style={{
                      // filter: 'invert(22%) sepia(97%) saturate(6775%) hue-rotate(321deg) brightness(98%) contrast(108%)'
                    }}
                    src="\loader\loader.gif"
                    alt="loader"
                  />
                </div>
                }
                </div>
                    
            </section>
        </div>
            
        </>
    )
}

export default LiveAuction


// {allNFTS?.map((elem) => {
//     // console.log('dsfjlasjfl', elem)
//     return (

//         <div className="col-xl-3 col-lg-4 col-md-6 col-12">
//             <Link to={{ pathname: `/place/` + elem.tokenId }}>
//                 <div class="card">
//                     <div className="card-img-top-div">
//                         {elem?.label?.substring(0, 37)}{elem?.label?.length > 37 && '(...)'}.{elem?.tld}
//                     </div>
                    
//                     {/* <div class="card-body-1">
//                         <div class="card-style-2">
//                             {elem?.Auction === false && <>
//                                 <h5 class="card-heading-1">Auction </h5>
//                                 <h5 class="card-heading-1 font-weight-bold text-dark">Ended </h5>
//                             </>}

//                             {elem?.Auction === true && (
//                                 <>
//                                     <h5 class="card-heading-1">Auction Ends</h5>
//                                     <p class="card-heading-2 justify-content-start">
//                                         {elem?.Timeline?.expiryTime && (
//                                             <Countdown
//                                                 date={elem?.expiryTime}
//                                                 renderer={({ days, hours, minutes, seconds }) => {
//                                                     return (
//                                                         <>
//                                                             <h5 class="card-heading-4">
//                                                                 {days < 10 && 0}{days} : {hours < 10 && 0}{hours} : {minutes < 10 && 0}{minutes} :{" "} {seconds < 10 && 0}{seconds}
//                                                             </h5>
//                                                         </>
//                                                     );
//                                                 }}
//                                             ></Countdown>
//                                         )}
//                                     </p>
//                                 </>)}
//                         </div>
//                         <div class="card-style-2">
//                             <h5 class="card-heading-1">Highest Bid</h5>
//                             <p class="card-heading-2 text-lowercase">
//                                 <img
//                                     class="img-fluid mr-1"
//                                     src="\assets\eth-icon.svg"
//                                     alt="img"
//                                 />
//                                 {elem?.HighestBid
//                                     ? parseInt(elem?.HighestBid).toFixed(4) /
//                                     1000000000000000000 || 70
//                                     : "70"}{" "}

//                             </p>
//                         </div>
//                     </div> */}
//                 </div>
//             </Link>
//         </div>

//     )
// })}
import React from 'react'
import "./howitworks.scss"
import { Player } from "video-react";
import ReactPlayer from 'react-player';

const HowItWorks = () => {
    return (
        <>
            <section className="howitworks">
                <div className="custom-containerl">
                    <div className="main-heading">
                        <h4>How it works</h4>
                    </div>
                    <div className="parent-content">
                        <div className="inner-content">
                            <div className="inner-text">
                                <img src="\assets\newlanding-assets\one.svg" alt="img" className='img-fluid' />
                                <h6>Mint a Domain and Become a <br /> Partner</h6>
                            </div>
                            <p className="para">To get started, mint a domain name NFT by paying $100 in $TOMI tokens. As the first minter, you'll receive a Partner NFT that entitles you to 25% of all future sales royalties for that domain. When someone places the first bid on your domain, you'll receive 20% of the difference between the bid price and the $100 minting fee, plus 25% of
 the final sale price as royalties </p>
                        </div>
                        <div className="inner-content">
                            <div className="inner-text">
                                <img src="\assets\newlanding-assets\two.svg" alt="img" className='img-fluid' />
                                <h6>Participate in the <br /> auction</h6>
                            </div>
                            <p className="para">Anyone can place a higher bid on a domain by offering at least 15% more than the current highest bid, paid in $TOMI tokens. If your bid is outbid, you'll receive your original purchase price back, plus an additional 20% of the difference between your bid and the new highest bid. The original minter (Partner) will receive 25% of the difference between the new bid and the previous highest bid as royalties. All payments and rewards are
handled through the tomi Domains dApp.</p>
                        </div>
                        <div className="inner-content">
                            <div className="inner-text">
                                <img src="\assets\newlanding-assets\three.svg" alt="img" className='img-fluid' />
                                <h6>Place a bid and <br /> own a domain</h6>
                            </div>
                            <p className="para">To win a domain, place a bid that is at least 15% higher than the current highest bid price. When you outbid someone, the previous highest bidder will receive their original bid amount back, plus an additional 20% of their bid price. Once the auction ends on May 15, 2024, the highest bidder will permanently own the domain URL and its associated NFT, with no renewal fees required.</p>
                        </div>
                    </div>
                    {/* <div className="main-video text-center">
                        <img src="\assets\newlanding-assets\video-banner.png" alt="img" className='img-fluid' />
                        <Player
                  playsInline
                  poster="https://res.cloudinary.com/drt6vurtt/video/upload/v1684246112/gems/video-dummy_yf6pbq.webm"
                  src="https://youtu.be/PhvatlXI70E"
                />
                        <ReactPlayer
                            className='react-player'
                            url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                        />
                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />
                        <iframe src="https://www.youtube.com/embed/PhvatlXI70E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default HowItWorks

import React from 'react';
import './faqsnew.scss';
const FaqsNew = () => {
    return (
        <>
            <section className="main-faqsnew ptb">
                <div className="custom-containerl">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="inner-content text-center">
                                <h2 className="main-heading">frequently asked questions</h2>
                                {/* <p className='para'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.</p> */}
                                {/* <h1 className="">Get the facts straight</h1> */}

                            </div>
                        </div>
                    </div>
                    <section className="main-faqs p-0 cgyvcsgvctvtscvtcv" id="faqs">

                        <div className="row">
                            <div className="col-sm-12 inner-upper">
                                <div className="inner-side">
                                    <div id="accordion">
                                        <div className="card">
                                            <div className="card-header" id="headingTwo">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <div className="parent-set-faqs">
                                                            <span className='font-weight-bold' style={{ color: "#FF0083", fontSize: "18px", fontFamily: "Gsemibold" }}>01. </span>
                                                            <h5 className=""> Why do I need a domain?</h5>
                                                        </div>
                                                        <p className='font-weight-bold plus-sign'>+</p>
                                                        <p className='font-weight-bold minus-sign d-none'>-</p>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                <div className="card-body">
                                                The tomiNet will work like today’s Worldwide Web–but blockchain-enabled for privacy. The tomi domain names will give you top level access to a tomi domain name with a .com or a .tomi address, plus you will get the ability to use the domain name for your wallet address for life. </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingThree">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <div className="parent-set-faqs">
                                                            <span className='font-weight-bold' style={{ color: "#FF0083", fontSize: "18px", fontFamily: "Gsemibold" }}>02. </span>
                                                            <h5 className=""> How is tomi’s domain different from any other domain?</h5>
                                                        </div>
                                                        <p className='font-weight-bold plus-sign'>+</p>
                                                        <p className='font-weight-bold minus-sign d-none'>-</p>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                <div className="card-body">
                                                tDNS offers an enhanced solution that surpasses both ICANN domains and crypto domains. While ICANN domains provide a user-friendly substitute for IP addresses, and crypto domains simplify blockchain addresses, tDNS goes beyond by offering superior capabilities. Unlike the other options, tDNS is not confined to a single network, allowing seamless access to websites on the tomi net, GovNet, and simultaneously via the same address. Moreover, tDNS enables the use of a single address across multiple blockchains, encompassing sol, avax, eth, btc, and other cryptocurrencies.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingfive">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefour">
                                                        <div className="parent-set-faqs">
                                                            <span className='font-weight-bold' style={{ color: "#FF0083", fontSize: "18px", fontFamily: "Gsemibold" }}>03. </span>
                                                            <h5 className=""> How is the income from domain sales distributed?</h5>
                                                        </div>
                                                        <p className='font-weight-bold plus-sign'>+</p>
                                                        <p className='font-weight-bold minus-sign d-none'>-</p>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapsefive" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                                                <div className="card-body">
                                                For the first minting of a domain name 100% of the income goes to the Tomi team and to the tomiDAO. <br />
                                                For each auction sale:
                                                    <ul className='set-decoration-custom'>
                                                        <li>The seller gets their full purchase price back, except for the Partner, who has purchased the privilege of getting royalties for life.</li>
                                                        <li>Of the difference between purchase price and the new bid:
                                                            <ul className='set-decoration-custom inner-list-style'>
                                                                <li>20% to the seller</li>
                                                                <li>25% to the Partner</li>
                                                                <li>30% to referral programs</li>
                                                                <li>25% split evenly between tomiDAO and tomi team</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    Amounts in the referral program that are not used will go to tomiDAO and development team.

                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingsix">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsesix" aria-expanded="false" aria-controls="collapsefour">
                                                        <div className="parent-set-faqs">
                                                            <span className='font-weight-bold' style={{ color: "#FF0083", fontSize: "18px", fontFamily: "Gsemibold" }}>04. </span>
                                                            <h5 className=""> How long does the tomi’s domain belong to me?</h5>
                                                        </div>
                                                        <p className='font-weight-bold plus-sign'>+</p>
                                                        <p className='font-weight-bold minus-sign d-none'>-</p>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapsesix" className="collapse" aria-labelledby="headingsix" data-parent="#accordion">
                                                <div className="card-body">
                                                Unlike other domain services, tomi’s domain is not a rental but yours forever.</div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingseven">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseseven" aria-expanded="false" aria-controls="collapsefour">
                                                        <div className="parent-set-faqs">
                                                            <span className='font-weight-bold' style={{ color: "#FF0083", fontSize: "18px", fontFamily: "Gsemibold" }}>05. </span>
                                                            <h5 className="">What wallet and currency do I need to make a bid?</h5>
                                                        </div>
                                                        <p className='font-weight-bold plus-sign'>+</p>
                                                        <p className='font-weight-bold minus-sign d-none'>-</p>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseseven" className="collapse" aria-labelledby="headingseven" data-parent="#accordion">
                                                <div className="card-body">
                                                You need Metamask or WalletConnect to log into the tDNS dApp and mint or make bids on domains. The tDNS sale will be on Ethereum Mainnet using $TOMI tokens, which are ERC20 tokens. You can buy $TOMI on Gate.io, Phemex, Huobi, Kucoin, Bybit, Bitbet, MEXC Global, and Crypto.com</div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headinglast">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapselast" aria-expanded="false" aria-controls="collapselast">
                                                        <div className="parent-set-faqs">
                                                            <span className='font-weight-bold' style={{ color: "#FF0083", fontSize: "18px", fontFamily: "Gsemibold" }}>06. </span>
                                                            <h5 className="">Do I have to renew the tomi domain? </h5>
                                                        </div>
                                                        <p className='font-weight-bold plus-sign'>+</p>
                                                        <p className='font-weight-bold minus-sign d-none'>-</p>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapselast" className="collapse" aria-labelledby="headinglast" data-parent="#accordion">
                                                <div className="card-body">
                                                No! You really own the domain–it’s not a subscription. The tomi domain is yours for as long as you hold the NFT. You can buy or sell a tomi domain at any time, and it is yours in perpetuity.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-sm-6 inner-upper">
                                <div className="inner-side ptb20 pt-0">
                                    <div id="accordion">

                                        <div className="card">
                                            <div className="card-header" id="headingTwo">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsesixx" aria-expanded="false" aria-controls="collapseTwo">
                                                        <div className="parent-set-faqs">
                                                            <span className='font-weight-bold' style={{ color: "#FF0083", fontSize: "18px", fontFamily: "Gsemibold" }}>06. </span>
                                                            <h5 className="">Lorem ipsum dolor sit amet consectetur adipiscing elit?</h5>
                                                        </div>
                                                        <p className='font-weight-bold plus-sign'>+</p>
                                                        <p className='font-weight-bold minus-sign d-none'>-</p>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapsesixx" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                <div className="card-body">
                                                    For the same reason that internet sites
                                                    use dns, because remembering an full
                                                    IP (10+ numbers) for each site is not
                                                    realistic, and certainly not convenient</div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingThree">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseThreee" aria-expanded="false" aria-controls="collapseThree">
                                                        <div className="parent-set-faqs">
                                                            <span className='font-weight-bold' style={{ color: "#FF0083", fontSize: "18px", fontFamily: "Gsemibold" }}>07. </span>
                                                            <h5 className="">Sed do eiusmod tempor incididunt ut labore?</h5>
                                                        </div>
                                                        <p className='font-weight-bold plus-sign'>+</p>
                                                        <p className='font-weight-bold minus-sign d-none'>-</p>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseThreee" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                <div className="card-body">
                                                    While ICANN domains gives you an easy-to-remember alternative to the IP adresses for websites, and crypto domains gives you an easy-to-
                                                    remember alternative to the long,
                                                    complicated blockchain address, the
                                                    tDNS gives you both of them, and does
                                                    it better. Not limiting you to one net or
                                                    the other- tDNS can direct you to
                                                    websites on the tomi net as well as
                                                    websites in the GovNet and simutansly
                                                    on the same time on the adress use-
                                                    case, one dress can be used for
                                                    multible different blockchains (such as
                                                    sol, avax, eth, btc, etc)
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingfive">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsefivee" aria-expanded="false" aria-controls="collapsefour">
                                                        <div className="parent-set-faqs">
                                                            <span className='font-weight-bold' style={{ color: "#FF0083", fontSize: "18px", fontFamily: "Gsemibold" }}>08. </span>
                                                            <h5 className="">Ut enim ad minim veniam, quis nostrud?</h5>
                                                        </div>
                                                        <p className='font-weight-bold plus-sign'>+</p>
                                                        <p className='font-weight-bold minus-sign d-none'>-</p>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapsefivee" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                                                <div className="card-body">
                                                    There are 2 timelines:
                                                    <ul>
                                                        <li>⚪ The mint - 70$ in $TOMI + ETH gas fees.</li>
                                                        <li>⚪ The auction - 24 Hours, the highest bidder will win.</li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingsix">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsesixxx" aria-expanded="false" aria-controls="collapsefour">
                                                        <div className="parent-set-faqs">
                                                            <span className='font-weight-bold' style={{ color: "#FF0083", fontSize: "18px", fontFamily: "Gsemibold" }}>09. </span>
                                                            <h5 className="">Exercitation ullamco laboris nisi ut aliquip ex?</h5>
                                                        </div>
                                                        <p className='font-weight-bold plus-sign'>+</p>
                                                        <p className='font-weight-bold minus-sign d-none'>-</p>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapsesixxx" className="collapse" aria-labelledby="headingsix" data-parent="#accordion">
                                                <div className="card-body">
                                                    Unlike other domain services, tomi’s
                                                    domain is not a rental but yours
                                                    forever.</div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingseven">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsesevenn" aria-expanded="false" aria-controls="collapsefour">
                                                        <div className="parent-set-faqs">
                                                            <span className='font-weight-bold' style={{ color: "#FF0083", fontSize: "18px", fontFamily: "Gsemibold" }}>10. </span>
                                                            <h5 className="">Duis aute irure dolor in reprehenderit in voluptate?</h5>
                                                        </div>
                                                        <p className='font-weight-bold plus-sign'>+</p>
                                                        <p className='font-weight-bold minus-sign d-none'>-</p>
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapsesevenn" className="collapse" aria-labelledby="headingseven" data-parent="#accordion">
                                                <div className="card-body">
                                                    the initial sale price of the
                                                    domain as well as a 5% royalty fee of
                                                    any susequent sale are devided
                                                    between the tomiDAO (40% of the
                                                    fee), the initial minter of the domain
                                                    (25% of the fee) and the core team
                                                    –
                                                    (35% of the fee).</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>


                    </section>
                </div>
            </section>
        </>
    )
}

export default FaqsNew;

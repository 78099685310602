import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./domainSearch.scss";
import ChildOfId from '../../hooks/dataFetchers/childIdOf'
import DomainMinting from '../../hooks/dataSenders/mintDomain'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { useEffect } from "react";
import Countdown from 'react-countdown';
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";
import axios from "axios";
import { async } from "q";
import { useRef } from 'react';
import Cart from "../cart/Cart";
import namer from "@rstacruz/startup-name-generator";
function DomainSearch({ domainProp, domainName, setLoader, setMinted, minted, setCartState, cartState }) {
  const [inputVal, setInputVal] = useState(domainName)
  const [domainType, setDomainType] = useState(parseInt(domainProp))
  const [show, setShow] = useState(false);
  const { domainMintingMethod } = DomainMinting()
  const [domainAvail, setDomainAvail] = useState(false)
  const handleClose = () => setShow(false);
  const [sugNameLoad, setSugNameLoad] = useState(false)
  const [show1, setShow1] = useState(false);
  const [mintRes, setMintRes] = useState()
  const [availabilityArray, setAvailabilityArray] = useState([]);
  const [toastDesign] = useState({
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  })
  const [pBid, setPBid] = useState()
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const { Mainfunct } = ChildOfId();
  let { account } = useWeb3React()
  const [domainAvailMeth, setDomainAvailMeth] = useState()
  const [sugNames, SetSugNames] = useState([])
  const [filterArray, setFilterArray] = useState(sugNames)
  const [normalDomArr, setNormalDomArr] = useState(sugNames)
  const [showTimer, setShowTimer] = useState(false)
  const [timerTime, setTimerTime] = useState(0)
  const [suggArr, setSugges] = useState()
  const domainTypebyProp = ["TOMI", "COM", "TOMI", "COM", "TOMI", "COM", "TOMI", "COM", "TOMI", "COM", "TOMI", "COM"]
  const inputRef = useRef(null);
  const [cartArr, setCartArr] = useState([])
  const [cartStorageData, setCartStorageData] = useState([])
  const getSearchData = async (domain, type) => {
    if (!domain || !type) {
      return false;
    }

    try {
      setLoader(true);
      const data = {
        query: `query MyQuery {
          auctionCreateds(orderBy: startTime, orderDirection: desc, where: {label: "${domain}", tld: "${type}"}) {
            expiryTime
            id
          }
        }`,
        variables: {},
      };

      const response = await axios.post(Environment.marketplaceGraph, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const minted = response?.data?.data?.auctionCreateds;
      // setLoader(false);

      if (minted?.length > 0) {
        return minted;
      } else {
        return false;
      }
    } catch (error) {
      setLoader(false);
      return false;
    }
  };
  const domainSearchFunc = async () => {
    try {
      setDomainAvail(false);
      const res = await Mainfunct(inputVal, domainTypebyProp[domainType].toLocaleLowerCase());
      setDomainAvailMeth(res);
      setDomainAvail(res);
    } catch (error) {
      setDomainAvail(false);
      toast.error('Domain Unavailable', toastDesign);
    } finally {
      setLoader(false);
    }
  };

  const mintdom = async () => {
    try {
      setShow(false);
      setShow1(true);

      const isMinted = await domainMintingMethod(inputVal, true);
      if (isMinted) {
        setTimerTime(65000);
        setShowTimer(true);
        const mintRes = await domainMintingMethod(inputVal, false);

        toast.success('Mint Successful', toastDesign);
        toast.info('Minted Domains take time to show', toastDesign);

        setShow1(false);
        setShow2(true);
        setMinted(!minted);
        setMintRes(mintRes);
      }
    } catch (error) {
      toast.error('Minting Failed', toastDesign);
      setShow(false);
      setShow1(false);
      setShow2(false);
    }
  };

  const inputVAlue = (e) => {
    const value = e.replace(/\s/g, "");
    const pattern = /^[A-Za-z0-9-]*$/;

    if (pattern.test(value)) {
      const lowercaseValue = value.toLowerCase();
      setInputVal(lowercaseValue);
      // Call other functions or perform additional actions if needed
      // domainSearchFunc();
    }
  };
  // const suggestedDomains = async () => {
  //   if (inputVal.endsWith("-")) {
  //     toast.info("Domain Should Not End With Special Character", toastDesign);
  //   } else if (inputVal.length < 3) {
  //     toast.info("Character Length should be greater than 2", toastDesign);
  //   } else {
  //     try {
  //       const res2 = [inputVal, inputVal, ...(namer(inputVal)?.slice(0, 10))];
  //       setSugges(res2)
  //       setSugNameLoad(true);
  //       setLoader(true);

  //       const requests = domainTypebyProp.map((domainType, id) =>
  //         Promise.all([
  //           Mainfunct(res2[id]?.toLowerCase(), domainType.toLocaleLowerCase()),
  //           getSearchData(res2[id]?.toLowerCase(), domainType.toLocaleLowerCase()),
  //         ])
  //       );
  //       setLoader(true);
  //       const results = await Promise.all(requests);

  //       const dumArray = results.map(([res]) => res);
  //       const dumArray2 = results.map(([, res2]) => res2);
  //       console.log('dumArray', dumArray);
  //       setPBid(dumArray2);
  //       SetSugNames(dumArray);
  //       setFilterArray(dumArray);
  //       setNormalDomArr(dumArray);
  //       setLoader(false);
  //     } catch (error) {
  //       console.error("Error occurred:", error);
  //       setLoader(false);
  //     } finally {
  //       setSugNameLoad(false);
  //     }
  //   }
  // };
  const suggestedDomains = async () => {
    if (inputVal.endsWith("-")) {
      toast.info("Domain Should Not End With Special Character", toastDesign);
    } else if (inputVal.length < 3) {
      toast.info("Character Length should be greater than 2", toastDesign);
    } else {
      try {
        const res2 = [inputVal, inputVal, ...(namer(inputVal)?.slice(0, 10))];
        setSugges(res2);
        setSugNameLoad(true);
        setLoader(true);

        const requests = domainTypebyProp.map((domainType, id) =>
          Promise.all([
            Mainfunct(res2[id]?.toLowerCase(), domainType.toLowerCase()),
            getSearchData(res2[id]?.toLowerCase(), domainType.toLowerCase()),
          ])
        );
        setLoader(true);
        const results = await Promise.all(requests);

        const dumArray = results.map(([res]) => res);
        const dumArray2 = results.map(([, res2]) => res2);
        console.log('dumArray', dumArray);
        setPBid(dumArray2);
        SetSugNames(dumArray);
        setFilterArray(dumArray);
        setNormalDomArr(dumArray);
        setLoader(false);

        // Combine `notAvaliable` function call
        const availabilityArray = dumArray.map( async (item, id) => {
          const domainType = domainTypebyProp[id]?.toLowerCase();
          const suggestion = res2[id]?.toLowerCase(); // Use res2 here since suggArr might not be defined at this point
          return await notAvaliable(domainType, suggestion);
        });

        // Store availabilityArray in state or variable as needed
        setAvailabilityArray(availabilityArray);

      } catch (error) {
        console.error("Error occurred:", error);
        setLoader(false);
      } finally {
        setSugNameLoad(false);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      suggestedDomains()
    }
  };
  const cartFun = async (id) => {
    // console.log('ddddddddddd', id)
  }
  // console.log('cartArr', cartArr, cartState)
  const addToCart = async (name, tld) => {
    let domain = { name, tld }
    // setCartArr((pre) => {
    //   return [...pre, domain]

    // })
    setCartArr([domain])
  }
  const mergeArrays = (arr1, arr2) => {
    // Combine both arrays into a single array
    const combinedArray = [...arr1, ...arr2];

    // Create an object to track unique objects based on name and tld
    const uniqueObjects = {};

    // Filter out duplicates and keep only the first occurrence of each unique combination
    const mergedArray = combinedArray.filter((obj) => {
      const key = obj.name + obj.tld;
      if (!uniqueObjects[key]) {
        uniqueObjects[key] = true;
        return true;
      }
      return false;
    });

    return mergedArray;
  };
  const removeItem = async (lowerCaseSuggArr, domainType) => {
    let res = cartStorageData?.filter((item) => {
      return (`${item?.name}${item?.tld}` !== `${lowerCaseSuggArr}${domainType}`)
    })
    localStorage.setItem('cart', JSON.stringify(res));
    setCartState(!cartState);
    // console.log('filter', res)
  }
  useEffect(() => {
    if (cartArr?.length !== 0) {
      let cartArrSt = localStorage.getItem('cart');
      cartArrSt = cartArrSt ? JSON.parse(cartArrSt) : []; // Parse only if cartArrSt is not null

      const mergedArray = mergeArrays(cartArrSt, cartArr);
      localStorage.setItem('cart', JSON.stringify(mergedArray));
      setCartState(!cartState);
    }
  }, [cartArr]);
  let notAvaliable =async  function (tld,name) {
    console.log(name, tld, 'availability');

    try {
      setLoader(true);
      const data = {
        query: `query MyQuery {
         auctionCreateds(where: {label: "${name}", tld: "${tld}"}) {
    expiryTime
  }
        }`,
        variables: {},
      };

      const response =await  axios.post(Environment.marketplaceGraph, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const expiryTime = response?.data?.data?.auctionCreateds[0]?.expiryTime; // 1715773475
      const currentTime = Math.floor(Date.now() / 1000);
      setLoader(false)
      console.log(expiryTime < currentTime, expiryTime, currentTime, 'availability');
      return expiryTime < currentTime
     
    
    } catch (error) {
      setLoader(false);
      return false;
    }
}
  useEffect(() => {
    if (inputVal) {
      domainSearchFunc()
      suggestedDomains()
    }
  }, [domainType, mintRes, account])
  useEffect(() => {
    let cartArr = localStorage.getItem('cart')
    cartArr = JSON.parse(cartArr)
    setCartStorageData(cartArr)
  }, [cartState !== undefined && cartState])
  console.log('cartStorageData', normalDomArr)
  return (
    <>
      <section className="domain-search">
        <div className="input-field">
          <div className="mysearchinput">
          <img
            src="\assets\search-normal.svg"
            alt="img"
            className="img-fluid  input-icon"
          />
          <input onKeyDown={handleKeyDown}
            ref={inputRef} onChange={(e) => { inputVAlue(e.target.value?.split('.')[0]); setDomainAvail(false); setNormalDomArr([]) }} value={inputVal} type="text" placeholder="domain.tomi" />
          <img
            onClick={() => setInputVal('')}
            src="\searchResults\add.png"
            className="img-fluid crosImg cPointer"
            alt=""
          />
          </div>

          <button
            disabled={domainType < 0 || inputVal == ''}
            onClick={() => {
              suggestedDomains()
            }} class="btn-searchbar" type="button">
            <img src="\assets\newlanding-assets\search-icon-mbl.svg" alt="img" className="img-fluid d-none showsearch-icon" />
            <span>Search</span>
          </button>
        </div>
        {console.log(normalDomArr, domainTypebyProp, suggArr,'fafafasfasfdas')}
        {inputVal &&
          normalDomArr?.map((item, id) => {
            const isAuction = !item;
        
            const domainType = domainTypebyProp[id]?.toLocaleLowerCase();
            const lowerCaseSuggArr = suggArr[id]?.toLowerCase();
            const linkToPath = `/progressbar/${domainType}/${lowerCaseSuggArr}`;
            const availability = availabilityArray[id]; // Get availability from domain
            console.log('availability', availability);
            const stored = cartStorageData?.some((item) => item?.name === lowerCaseSuggArr && item?.tld === domainType);
            return (
              <div className={`availableDomain${isAuction ? ' mb-0 pb-0' : ''}`} key={id}>
                <div className="row">
                  <div className="col-sm-9 my-auto">
                    <div className="d-flex domainMain">
                      <p>
                        <span>{lowerCaseSuggArr}</span>.{domainType}
                      </p>
                      <div className={`d-flex availtick ${isAuction ? availability? " bg-danger" : ' bg-warning' : ''}`}>
                        <img src="\searchResults\whitetick.svg" alt="" />
                        <p>{isAuction ? availability ? 'Unavailable' : 'In Auction' : 'Available'}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="d-flex justify-content-md-end domainRightHeartDiv">
                      {isAuction ? (
                        availability ? null :
                        <Link to={`/place/${pBid[id][0]?.id}`} className="cartButton text-center w-50 cartButton2">
                          Place a bid
                        </Link>
                      ) : (
                        <>
                          {stored ? <div onClick={() => removeItem(lowerCaseSuggArr, domainType)} className="add-cart-btn cPointer"><img src={`/cart/removecart.svg`} alt="img" className="img-fluid" /></div>
                            :
                            <div onClick={() => addToCart(lowerCaseSuggArr, domainType)} className="add-cart-btn cPointer"><img src={`/cart/addcart.svg`} alt="img" className="img-fluid" /></div>
                          }
                          {/* <Cart path="\cart\addcart.svg" bool={false} onClick={() => cartFun(pBid[id][0]?.id)} /> */}
                          <Link to={linkToPath} className="cartButton text-center w-50">
                            {true ? 'Request To Register' : 'Already Registered'}
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}


      </section>
      <section className="modal-mint1">
        <Modal show={show} onHide={handleClose} centered className="mint-modal">
          <Modal.Header closeButton>
            <Modal.Title>Register & Mint</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <a className="register-btn" onClick={mintdom}>Register & Mint</a>
          </Modal.Body>
        </Modal>
      </section>
      <section className="modal-mint">
        <Modal show={show1}
          centered className="mint-modal">
          <Modal.Header closeButton>
            <Modal.Title>Minting</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="inner-content-minting">
              <img src="\assets\laoding.png" alt="img" className="img-fluid rotate" />
              <p>Your NFT is minting</p>
              {showTimer ? <p>Estimate Time <span className='common'> <Countdown date={Date.now() + parseInt(timerTime)} /></span> Minute</p>
                : <p>Request To <span className="common">Register</span> </p>
              }
            </div>
          </Modal.Body>
        </Modal>
      </section>
      <section className="modal-mint">
        <Modal show={show2}
          onHide={handleClose2}
          centered className="mint-modal">
          <Modal.Header closeButton>
            <Modal.Title>MintING</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="inner-content-minting">
              <img src="\assets\tick-circle.png" alt="img" className="img-fluid" />
              <p>Your NFT is minted successfully</p>
              <h6>And placed on marketplace for an auction for 24h period</h6>
              <Link to="/domainSearhResult/active/mm" onClick={() => { handleClose2(); handleClose() }}><a className="register-btn"> Go Checkout My Domain</a></Link>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>

  );
}

export default DomainSearch;

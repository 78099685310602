import React, { useEffect, useState } from 'react'
import "./refferal.scss"
import HeaderNew from '../LandingNew/HeaderNew/HeaderNew'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Pagination from 'react-bootstrap/Pagination';
import CopyToClipboard from 'react-copy-to-clipboard'
import Loader from '../../hooks/loader';
import axios from 'axios';
import { API_URL } from '../../ApiUrl';
import Demo from './demo';
import Demo2 from './demo2';
import Navbar from '../landing/header/Navbar';
import { useWeb3React } from '@web3-react/core';
import CashBack from '../MyCashback/CashBack';
import { toast } from 'react-toastify';
import TableRow from './TableRow';
import AffiliateClaimRewardMany from '../../hooks/dataSenders/compensationMultiple';
import Modal from 'react-bootstrap/Modal';

const Refferal = () => {
    const { account } = useWeb3React();
    const [copied, setCopied] = useState(false)
    const user = localStorage.getItem('user')
    const [loader, setLoader] = useState(false);
    const [referrals, setReferrals] = useState([])
    const [affiliates, setAffiliates] = useState([])
    const [limit, setLimit] = useState(10)
    const [pageNO, setPageNo] = useState(1)
    const [apiStatus, setApiCallStatus] = useState(false)
    const [limit2, setLimit2] = useState(20)
    const [pageNO2, setPageNo2] = useState(1)
    const [claimIds, setClaimIds] = useState([]);
    const [show1, setShow1] = useState(false);
    const [show, setShow] = useState(false);
    const [totalTomi, setTotalTomi] = useState(0);
    const [tot, setTot] = useState(false);
    const userObj = JSON.parse(user)

    const { ClaimAffiliateMany } = AffiliateClaimRewardMany()
    // console.log('dsfasdfadsf', affiliates)
    const settime = () => {
        setTimeout(() => {
            setCopied(false)
        }, 1500);
    }
    // console.log('aldflasjdfl',typeof pageNO,typeof limit)
    const getReferrals = async () => {
        let tok = localStorage.getItem("accessToken");
        try {
            setLoader(true)
            const res = await axios.get(`${API_URL}/customers/referrals?offset=${parseInt(pageNO)}&limit=${parseInt(limit)}`, {
                headers: {
                    authorization: `Bearer ` + tok
                }
            })
            setLoader(false)
            setReferrals(res?.data?.data)
            // // console.log('res', res?.data?.data?.affiliates)
        } catch (error) {
            //   if (error.code == 401) {
            //     refreshToken()
            //   }
            setLoader(false)
            // // console.log('res error', error)
        }
    }
    const getAffiliates = async () => {
        let tok = localStorage.getItem("accessToken");
        try {
            setLoader(true)
            const res = await axios.get(`${API_URL}/compensation/my?offset=${parseInt(pageNO2)}&limit=${parseInt(limit2)}`, {
                headers: {
                    authorization: `Bearer ` + tok
                }
            })
            setLoader(false)
            console.log("res?.data?.data", res?.data?.data?.count, res?.data?.data?.totalAmount)
            if (res?.data?.data?.totalAmount) {
                setTotalTomi(res?.data?.data?.totalAmount);
            } else {
                setTotalTomi(0.00);
            }
            setAffiliates(res?.data?.data)
            // console.log('res', res?.data?.data)
        } catch (error) {
            //   if (error.code == 401) {
            //     refreshToken()
            //   }
            setLoader(false)
            // // console.log('res error', error)
        }
    }
    useEffect(() => {
        getReferrals()
    }, [limit, pageNO, account])
    useEffect(() => {
        getAffiliates()
    }, [limit2, pageNO2, account, apiStatus])

    let toastDesign = {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }

    const ClaimAll = () => {
        if (account) {
            if (claimIds?.length > 0 || affiliates?.compensation?.length > 0) {
                let dumArrTok = [];
                if (claimIds?.length === 0) {
                    for (let i of affiliates?.compensation) {
                        if (i?.claimed === false) {
                            dumArrTok.push(i?._id)
                        }
                    }
                } else {
                    dumArrTok = claimIds;
                }
                if (dumArrTok?.length > 0) {
                    handleShow2();
                    try {
                        setLoader(true)
                        let tok = localStorage.getItem("accessToken");
                        let data = {
                            compensationIds: dumArrTok,
                            walletAddress: account?.toLowerCase()
                        }
                        var config = {
                            method: "patch",
                            url: `${API_URL}/compensation/claim`,
                            data: data,
                            headers: {
                                authorization: `Bearer ` + tok
                            }
                        };
                        axios(config)
                            .then(async function (response) {
                                let tokenArr = [];
                                let amountArr = [];
                                let ids = [];
                                let v = [];
                                let r = [];
                                let s = [];
                                for (let i of response?.data?.data) {
                                    tokenArr?.push(i?.tokenId);
                                    amountArr?.push(i?.amount?.toString());
                                    ids?.push(i?.compensationId)
                                    v?.push(i?.v);
                                    r?.push(i?.r);
                                    s?.push(i?.s);
                                }
                                try {
                                    const claimed = await ClaimAffiliateMany(tokenArr, amountArr, account, v, r, s, ids);
                                    setClaimIds([])
                                    setShow(true);
                                    setShow2(false);
                                } catch (err) {
                                    setShow2(false)
                                    console.log("err", err)
                                    let data = {
                                        compensationIds: dumArrTok,
                                        walletAddress: account?.toLowerCase()
                                    }
                                    var config = {
                                        method: "patch",
                                        url: `${API_URL}/compensation/claim/revert`,
                                        data: data,
                                        headers: {
                                            authorization: `Bearer ` + tok
                                        }
                                    };
                                    axios(config)
                                        .then(async function (response) {
                                            setLoader(false)
                                        }).catch(function (error) {
                                            setShow2(false);
                                        });
                                }
                                setLoader(false)
                                setApiCallStatus(!apiStatus)
                            }).catch(function (error) {
                                setShow2(false)
                                let data = {
                                    compensationIds: dumArrTok,
                                    walletAddress: account?.toLowerCase()
                                }
                                var config = {
                                    method: "patch",
                                    url: `${API_URL}/compensation/claim/revert`,
                                    data: data,
                                    headers: {
                                        authorization: `Bearer ` + tok
                                    }
                                };
                                axios(config)
                                    .then(async function (response) {
                                        setLoader(false)
                                    }).catch(function (error) {
                                    });
                                setShow1(false)
                                // console.log('first', error)
                                setLoader(false)
                                setShow2(false);
                            });
                    } catch (error) {
                        setShow1(false)
                        // console.log('first', error)
                        setLoader(false)
                    }
                }
            } else {
                toast.error("You don't have any referals to claim", toastDesign)
            }
        } else {
            toast.info('Connect Wallet')
        }
    }

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    useEffect(() => {
        if (totalTomi === 0 && tot === false) {
            // let totalVal = 0;
            // for (var i = 0; i < affiliates?.compensation?.length; i++) {
            //     if (affiliates?.compensation[i]?.amount && affiliates?.compensation[i]?.claimed === false) {
            //         let amount = affiliates?.compensation[i]?.amount / 10 ** 18;
            //         // console.log("amount", amount, totalVal)
            //         totalVal = amount + totalVal
            //         if (i + 1 === affiliates?.compensation?.length) {
            //             setTot(true);
            //         }
            //     }
            // }
            // setTotalTomi(totalVal)
        }
    }, [affiliates?.compensation?.length])

    return (
        <>
            {loader && <Loader />}
            <Navbar tNav={'tNav'} />
            <section className="refferal-section">
                <img src="\assets\bg-refferal.png" alt="img" className='img-fluid bg-refferal' />
                <div className="custom-containerl">
                    <div className="main-heading">
                        <h2>Domains Referral Program</h2>
                        <p>Invite others to join your network and make money</p>
                    </div>
                    <div className="inner-box">
                        <div className="left">
                            <div className="inner-heading">
                                <h6>Refer and Earn with Domains</h6>
                                <p>Invite others to join your network and make money on commissions</p>
                            </div>
                            <div className="bottom-box">
                                <h6>my Referral Code</h6>
                                <div className="address-div">
                                    {/* http://54.187.195.253:40731?{userObj?.referenceCode} */}
                                    <p>https://domains.tomi.com/?{userObj?.referenceCode}</p>
                                    {!copied ?
                                        <CopyToClipboard text={`https://domains.tomi.com/?${userObj?.referenceCode}`}
                                            onCopy={() => { setCopied('Copied'); settime() }}>
                                            <img src="\assets\copy-address.svg" alt="img" className='img-fluid' style={{ cursor: "pointer" }} /></CopyToClipboard> :
                                        <p className=" copy text-common">Copied</p>}
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <img src="\assets\refer-img.png" alt="img" className='img-fluid' />
                        </div>
                    </div>
                    <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-examplefgfgf"
                        className="refer-tabs"
                    >
                        <Tab eventKey="home" title="My Referrals">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Nickname</th>
                                            <th>Wallet Address</th>
                                            <th>Date Joined</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {referrals?.customers?.map((item, id) => {
                                            return (
                                                <tr>
                                                    <td>{id + 1}</td>
                                                    <td>{item?.nickName ? item?.nickName : '----'}</td>
                                                    <td>{item?.walletAddress}</td>
                                                    <td>{new Date(item?.createdAt).toLocaleDateString('en-GB')}</td>
                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </table>
                            </div>
                            <div className="footer-content">
                                <div className="left-f">
                                    <h6>SHOWING 1-10 OF {referrals?.count}</h6>
                                </div>
                                <div className="right-f">
                                    <Demo prop={referrals} setPageNo={setPageNo} />
                                </div>
                            </div>
                        </Tab>
                        {/*  */}
                        {userObj?.referredBy &&
                            <Tab eventKey="profile" title="My Cashbacks" >
                                <CashBack disN={true} setLoader={setLoader} />
                            </Tab>
                        }
                        <Tab eventKey="mycashback" title="My Compensations">
                            <div className="total-compensation">
                                <div className="left">
                                    <div className="text-content">
                                        <h6>My Total Compensations</h6>
                                        <h5>{totalTomi?.toFixed(4)} TOMI <span></span></h5>
                                    </div>
                                </div>
                                <div className="right">
                                    <button onClick={() => ClaimAll()} className='btn-claimall'>Claim All</button>
                                </div>
                            </div>
                            <div className="main-head">
                                <h6>My Compensation History</h6>
                            </div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Domain</th>
                                            <th>Level</th>
                                            <th>Amount</th>
                                            <th>Referrer Wallet Address</th>
                                            <th>Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {affiliates?.compensation?.map((item, id) => {
                                            return (
                                                <TableRow totalTomi={totalTomi} getAffiliates={getAffiliates} setShow={setShow} show={show} setShow1={setShow1} show1={show1} setClaimIds={setClaimIds} claimIds={claimIds} item={item} setLoader={setLoader} setApiCallStatus={setApiCallStatus} apiStatus={apiStatus} />
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="footer-content">
                                <div className="left-f">
                                    <h6>SHOWING 1-10 OF {affiliates?.count}</h6>
                                </div>
                                <div className="right-f">
                                    <Demo2 prop={affiliates} setPageNo={setPageNo2} />
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </section>

            <Modal className='oops-modal' show={show2} onHide={handleClose2} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Claim in process...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="waiting-div">
                        <img src="\assets\newlanding-assets\waiting-icon.svg" alt="img" className='img-fluid' />
                        <h6 className='waiting-text'>Please don't refresh your screen</h6>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Refferal

import React from 'react'
import "./decentralizedweb.scss"

const DecentralizedWeb = () => {
  return (
    <>
      <section className="decentralized">
        <div className="custom-containerl">
            <div className="parent">
            <div className="row">
                <div className="col-xl-6 col-12 p-0 m-auto">
                    <div className="inner-text">
                        <h6>Decentralized Web 3.0 domains on the tomiNET</h6>
                        <p>The tDNS protocol (DNS protocol created for the tomiNet) is managed on the blockchain through NFTs that represent ownership of the URL, and the actual addresses are encrypted, so that no entity on the network will ever be able to block an approved address.</p>
                    </div>
                </div>
                <div className="col-xl-6 col-12 p-0 m-auto text-right text-centerr">
                    <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1715277878/tDNS/Frame_40005_vmon53.png" alt="img" className='img-fluid' />
                </div>
            </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default DecentralizedWeb

import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../landing/header/Navbar'
import './watchlist.scss'
function Watchlist() {
    return (
        <>
        <Navbar />
        <div className="container-fluid watchlist p-0">
            <div className="row">
                <div className="col-lg-9 mx-auto">
                    <section className='domain-search'>
                        <div className="watchlist-h">
                            <h1>Your watchlist is empty</h1>
                            <p>Add an NFT domain to start your journey on the decentralized web.</p>
                        </div>
                        <div className="input-field">
                            <img src="\assets\search-normal.svg" alt="img" className="img-fluid  input-icon" />
                            <input type="text" placeholder="Search domain names" />
                            <img src="\searchResults\add.png" className='img-fluid crosImg' alt="" />
                            <Link to=""><button class="btn-searchbar" type="button" >
                                {/* <img src="\main-banner\search-normal.svg" alt="img" className="img-fluid  mr-3" /> */}
                                Search
                            </button></Link>
                        </div>
                        {/* <div className="searchIcondiv">
        <img src="\assets\search-normal.svg" className='img-fluid' alt="" />
        <p>Find your perfect domain by typing into the search field above.</p>
    </div> */}
<h1 className='wat-h'>Watchlist</h1>
                        <div className="availableDomain availableDomain-suggested ">
                            <div className="row">
                                <div className="col-sm-6 my-auto">
                                    <div className="d-flex domainMain">
                                        <div className='d-flex availtick availtick2 bg-transparent'><img src="\searchResults\tick2x.png" alt="" /></div> <p><span>domain</span> .nft</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="d-flex justify-content-md-end domainRightHeartDiv">
                                        <p>$40,000</p>
                                        <img src="\searchResults\heart.png" className='img-fluid hImg' alt="" />
                                        <button className='cartButton'><img src="\searchResults\shopping-cart.svg" alt="" />Add to cart</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 my-auto">
                                    <div className="d-flex domainMain">
                                        <div className='d-flex availtick availtick2 bg-transparent'><img src="\searchResults\tick2x.png" alt="" /></div> <p><span>domain</span> .nft</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="d-flex justify-content-md-end domainRightHeartDiv">
                                        <p>$40,000</p>
                                        <img src="\searchResults\heart.png" className='img-fluid hImg' alt="" />
                                        <button className='addedSuccess d-flex align-items-center'><img src="\searchResults\tick-single.png" alt="" />Add to cart</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 my-auto">
                                    <div className="d-flex domainMain">
                                        <div className='d-flex availtick availtick2 bg-transparent'><img src="\searchResults\tick2x.png" alt="" /></div> <p><span>domain</span> .nft</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="d-flex justify-content-md-end domainRightHeartDiv">
                                        <p>$40,000</p>
                                        <img src="\searchResults\heart.png" className='img-fluid hImg' alt="" />
                                        <button className='addedSuccess d-flex align-items-center'><img src="\searchResults\tick-single.png" alt="" />Add to cart</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 my-auto">
                                    <div className="d-flex domainMain">
                                        <div className='d-flex availtick availtick2 bg-transparent'><img src="\searchResults\tick2x.png" alt="" /></div> <p><span>domain</span> .nft</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="d-flex justify-content-md-end domainRightHeartDiv">
                                        <p>$40,000</p>
                                        <img src="\searchResults\heart.png" className='img-fluid hImg' alt="" />
                                        <button className='cartButton'><img src="\searchResults\shopping-cart.svg" alt="" />Add to cart</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 my-auto">
                                    <div className="d-flex domainMain">
                                        <div className='d-flex availtick availtick2 bg-transparent'><img src="\searchResults\tick2x.png" alt="" /></div> <p><span>domain</span> .nft</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="d-flex justify-content-md-end domainRightHeartDiv">
                                        <p>$40,000</p>
                                        <img src="\searchResults\heart.png" className='img-fluid hImg' alt="" />
                                        <button className='cartButton'><img src="\searchResults\shopping-cart.svg" alt="" />Add to cart</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 my-auto">
                                    <div className="d-flex domainMain">
                                        <div className='d-flex availtick availtick2 bg-transparent'><img src="\searchResults\tick2x.png" alt="" /></div> <p><span>domain</span> .nft</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="d-flex justify-content-md-end domainRightHeartDiv">
                                        <p>$40,000</p>
                                        <img src="\searchResults\heart.png" className='img-fluid hImg' alt="" />
                                        <button className='cartButton'><img src="\searchResults\shopping-cart.svg" alt="" />Add to cart</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 my-auto">
                                    <div className="d-flex domainMain">
                                        <div className='d-flex availtick availtick2 bg-transparent'><img src="\searchResults\tick2x.png" alt="" /></div> <p><span>domain</span> .nft</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="d-flex justify-content-md-end domainRightHeartDiv">
                                        <p>$40,000</p>
                                        <img src="\searchResults\heart.png" className='img-fluid hImg' alt="" />
                                        <button className='addedSuccess d-flex align-items-center'><img src="\searchResults\tick-single.png" alt="" />Add to cart</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 my-auto">
                                    <div className="d-flex domainMain">
                                        <div className='d-flex availtick availtick2 bg-transparent'><img src="\searchResults\tick2x.png" alt="" /></div> <p><span>domain</span> .nft</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="d-flex justify-content-md-end domainRightHeartDiv">
                                        <p>$40,000</p>
                                        <img src="\searchResults\heart.png" className='img-fluid hImg' alt="" />
                                        <button className='cartButton'><img src="\searchResults\shopping-cart.svg" alt="" />Add to cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div></div>
        </>
    )
}

export default Watchlist

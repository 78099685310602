import React from 'react'
import "./threebanners.scss"

const ThreeBanners = () => {
    return (
        <>
            <section className="befirst">
                <div className="custom-containerl">
                    <div className="row">
                        <div className="col-xl-6 col-12 padd-sm pl-0 text-centerr ordersm-1">
                            <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1715274759/tDNS/metaverse-deal_1_h4q74r.png" alt="img" className='img-fluid' />
                        </div>
                        <div className="col-xl-6 col-12 padd-sm m-auto p-0 ordersm-2">
                           <div className="inner-text">
                           <h6>Be the First and Earn Royalties</h6>
                           <p>As the original minter of a tomi domain, you'll become the Partner NFT holder and earn 25% royalties on all future sales of that domain URL, in perpetuity. After the auction year ends on May 15, 2024, you can freely sell your tomi domain NFTs through the tomi Domains dApp or any open auction site, at any price you desire.</p>
                           </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="buydomain">
                <div className="custom-containerl">
                    <div className="row">
                        <div className="col-xl-7 col-12 padd-sm m-auto pl-0 ordersm-2">
                           <div className="inner-text">
                           <h6>Buy a domain on the new internet</h6>
                           <p>So what happens after May 15, 2024? First of all, at that moment, the holders of the NFTs can build their websites on the domains they own! Secondly, anyone can mint any domain that has not been minted in the first year. The auction period will be 48 hours and at the end of that time, the highest bidder will receive the domain name.</p>
                           </div>
                        </div>
                        <div className="col-xl-5 col-12 padd-sm text-right p-0 text-centerr">
                            <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1715274889/tDNS/cryptocurrency-bid_1_bksrds.png" alt="img" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
            {/* new section set here......................... */}
            <section className="rewardeveryone">
                <div className="custom-containerl">
                    <div className="row">
                        <div className="col-xl-6 col-12 padd-sm pl-0 text-centerr ordersm-1">
                            <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1715274988/tDNS/pie-chart_2_vn8yzs.png" alt="img" className='img-fluid' />
                        </div>
                        <div className="col-xl-6 col-12 padd-sm m-auto p-0 ordersm-2">
                           <div className="inner-text">
                           <h6>Rewards for everyone!</h6>
                           <p>The rewards program ensures that funds go into the tomi treasury for maintaining the network, the DAO for the community, the Partner, the sellers on the auction–and to anyone who refers someone to the program.</p>
                           </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="refferprogram">
                <div className="custom-containerl">
                    <div className="row">
                        <div className="col-xl-7 col-12 padd-sm m-auto pl-0 ordersm-2">
                           <div className="inner-text">
                           <h6>Referral program</h6>
                           <p>tDNS offers a 5-tier referral program that rewards you for introducing new members to the platform. Your referred friends earn 5% more on their first domain sale compared to non-referred members, and you get a 12.5% referral bonus! 
                           <a style={{color: "#FF0083"}} href="\tDNS.pdf" target='_blank'> Download our PDF</a> guide to learn all the details.</p>
                           </div>
                        </div>
                        <div className="col-xl-5 col-12 padd-sm text-right p-0 text-centerr">
                            <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1715275265/tDNS/referral_program_1_b9spsx.png" alt="img" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    )
}

export default ThreeBanners

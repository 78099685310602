import React from 'react'
import BannerNew from './BannerNew/BannerNew'
import HeaderNew from './HeaderNew/HeaderNew'
import HowItWorks from './HowItWorks/HowItWorks'
import LiveAuction from '../landing/LiveAuction/LiveAuction'
import ThreeBanners from './Threebanners/ThreeBanners'
import DecentralizedWeb from './DecentralizedWeb/DecentralizedWeb'
import FaqsNew from './FaqsNew/FaqsNew'
import GetInvolved from './GetInvolved/GetInvolved'
import PartnersNew from './PartnersNew/PartnersNew'
import Navbar from '../landing/header/Navbar'

const LandingNew = () => {
  return (
    <>
      <div className="wrapper-color-cahnge">
        <Navbar  setTab={'common'} setTab2={''} setTab3={''} tNav={'tNav'}/>
        <BannerNew />
        <LiveAuction />
        <HowItWorks />
        <ThreeBanners />
        <DecentralizedWeb />
        {/* <PartnersNew /> */}
        <FaqsNew />
        <GetInvolved />
      </div>
    </>
  )
}

export default LandingNew

import React from 'react'
import "./getinvolved.scss"

const GetInvolved = () => {
    return (
        <>
            <section className="get-involved">
                <div className="custom-containerl">
                    <div className="main-heading">
                        <h6>Get Involved</h6>
                        <p>Join us, you are tomi.</p>
                    </div>
                    <div className="parent">
                        <div className="inner set-bottom-marginsm">
                            <img src="\assets\newlanding-assets\joindiscord.svg" alt="img" className='img-fluid' />
                            <h6>Join our Discord Community</h6>
                            <a href="https://discord.gg/tomipioneers" target='_blank'>Join Discord <img src="\assets\newlanding-assets\arrow-right.svg" alt="img" className='img-fluid ml-2' /></a>
                        </div>
                        <div className="inner set-bottom-marginsm">
                            <img src="\assets\newlanding-assets\whitepaper.svg" alt="img" className='img-fluid' />
                            <h6>Read our Vision</h6>
                            <a href="https://tomi.com/vision" target='_blank'>Read Vision <img src="\assets\newlanding-assets\arrow-right.svg" alt="img" className='img-fluid ml-2' /></a>
                        </div>
                        <div className="inner">
                            <img src="\assets\newlanding-assets\tele.svg" alt="img" className='img-fluid' />
                            <h6>Have a question?</h6>
                            <a href="https://t.me/tomi_domain_name_syatem" target='_blank'>Contact us <img src="\assets\newlanding-assets\arrow-right.svg" alt="img" className='img-fluid ml-2' /></a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GetInvolved
